html, body {
  font-family: PT Mono, monospace;
  font-size: 20px;
}

.Calculator {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.Keypad {
  border-collapse: collapse;
  width: 50%;
}

.Keypad .digit, .Keypad .operator, .Keypad .empty {
  cursor: pointer;
  text-align: center;
  border: 1px solid #000;
  width: 5vw;
  height: 5vw;
  padding: .5rem;
}

.Keypad .operator {
  color: #fff;
  background-color: #333;
  border-color: #000;
}

.Keypad .operator:hover {
  color: #333;
  background-color: #ff0;
}

.Keypad .operator:active {
  color: #333;
  background-color: #f0f;
}

.Keypad .operator-reset {
  background-color: #f08080;
}

.Keypad .digit:hover {
  color: #333;
  background-color: #f0f0f0;
}

.Keypad .digit:hover:active {
  color: #333;
  background-color: #f0f;
}

.Keypad .empty {
  cursor: default;
}

.Display {
  text-align: center;
  background: #00bfff;
  border-radius: 5px;
  width: 50%;
  margin-bottom: 10px;
  padding: 10px 0;
  font-size: 3rem;
  font-weight: bold;
  position: relative;
}

.Display .secondary-value {
  opacity: .5;
  font-size: 1rem;
  font-weight: normal;
  position: absolute;
  bottom: 5px;
  left: 5px;
}

/*# sourceMappingURL=index.8d189bc8.css.map */
